document.addEventListener("DOMContentLoaded", () => {
  const cards = document.querySelectorAll(".card");
  let totalClicks = 0; // Загальна кількість кліків по всіх карточках
  const messages = document.querySelectorAll(".win-message"); // Знаходимо всі повідомлення
  const modal = document.querySelector(".modal"); // Знаходимо модальне вікно

  cards.forEach((card) => {
    card.addEventListener("click", function () {
      if (totalClicks < 3) {
        // Забезпечуємо, що повідомлення з'являються лише до третього кліку включно
        const message = this.children[totalClicks]; // Вибір відповідного повідомлення
        message.style.opacity = 1;
        this.style.pointerEvents = "none"; // Вимкнення подальших кліків по цій карточці
        totalClicks++;

        if (totalClicks < messages.length) {
          // Приховуємо поточне повідомлення
          if (totalClicks > 0) messages[totalClicks - 1].style.display = "none";
          // Показуємо наступне повідомлення
          messages[totalClicks].style.display = "flex";

          //  totalClicks++;

          if (totalClicks === 3) {
            // Якщо було зроблено три кліки
            setTimeout(() => {
              modal.classList.remove("hidden");
              modal.classList.add("shown");
            }, 2000); // Показуємо модальне вікно через 2 секунди
          }
        }
      }
    });
  });
});
